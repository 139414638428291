// extracted by mini-css-extract-plugin
export var blocksInRowsWithOneActive = "payload-module--blocks-in-rows-with-one-active--695e6";
export var blocksInRowsWithOneActiveButtonWithArrows = "payload-module--blocks-in-rows-with-one-active-button-with-arrows--6104c";
export var blocksInRowsWithOneActiveGrey = "payload-module--blocks-in-rows-with-one-active-grey--022f2";
export var blocksInRowsWithOneActiveGreyBlocksContainer = "payload-module--blocks-in-rows-with-one-active-grey-blocks-container--48c7a";
export var blocksInRowsWithOneActiveGreyInnerWrapper = "payload-module--blocks-in-rows-with-one-active-grey-inner-wrapper--b8c17";
export var blocksInRowsWithOneActiveGreySubtitle = "payload-module--blocks-in-rows-with-one-active-grey-subtitle--53cd7";
export var blocksInRowsWithOneActiveGreyTitlesContainer = "payload-module--blocks-in-rows-with-one-active-grey-titles-container--b1685";
export var blocksInRowsWithOneActiveInnerWrapper = "payload-module--blocks-in-rows-with-one-active-inner-wrapper--f5a2d";
export var blocksInRowsWithOneActiveSubtitle = "payload-module--blocks-in-rows-with-one-active-subtitle--23f5b";
export var blocksInRowsWithOneActiveTitlesContainer = "payload-module--blocks-in-rows-with-one-active-titles-container--9fe53";
export var borderWidth = "payload-module--border-width--e82a6";
export var contactButtonImageCustomSection = "payload-module--contact-button-image-custom-section--3b7db";
export var contactButtonImageSectionButton = "payload-module--contact-button-image-section-button--a4c50";
export var contactButtonImageSectionContent = "payload-module--contact-button-image-section-content--bce9f";
export var contactButtonImageSectionCustomContainer = "payload-module--contact-button-image-section-custom-container--dc933";
export var contactButtonImageSectionImageContainer = "payload-module--contact-button-image-section-image-container--2a349";
export var contactButtonImageSectionInnerWrapper = "payload-module--contact-button-image-section-inner-wrapper--0bee6";
export var contactButtonImageSectionText = "payload-module--contact-button-image-section-text--14841";
export var desktopContainer = "payload-module--desktop-container--0d20d";
export var header = "payload-module--header--dd62f";
export var headerBorder = "payload-module--header-border--ee6d9";
export var headerTitleAndBorderContainer = "payload-module--header-title-and-border-container--48321";
export var opacitySliderInnerWrapper = "payload-module--opacity-slider-inner-wrapper--00211";
export var opacitySliderTitlesContainer = "payload-module--opacity-slider-titles-container--eceb7";
export var sectionUnderHeaderButton = "payload-module--section-under-header-button--a6c92";
export var sectionUnderHeaderContentWrapper = "payload-module--section-under-header-content-wrapper--81bb8";
export var techStackCardContainer = "payload-module--tech-stack-card-container--d58df";
export var techStackContainer = "payload-module--tech-stack-container--f1da1";
export var techStackInnerWrapper = "payload-module--tech-stack-inner-wrapper--4cbf6";
export var techStackTitlesContainer = "payload-module--tech-stack-titles-container--7a95d";
export var techStackViewDetails = "payload-module--tech-stack-view-details--703f6";