import React from 'react';

import { paths } from '@src/constants/pathsEnum';
import featuredImage from './images/featured-image.jpg';
import bannerImage from '@commons/contact-button-image-section/images/image4.png';
import { python, rust, go, cPlusPlus, dpdk, tRex, sonic, openVSwitch, tungstenFabric } from '@images/logos-techstack';

import * as styles from './payload.module.scss';

export const SEOProps = {
  title: 'Equipment | Hardware Software as a Service - CodiLime',
  description:
    'Our equipment is both low-level and embedded services. Since 2011, we’ve been working hard to uncover the full potential of our partners’ hardware.',
  featuredImage: `https://codilime.com${featuredImage}`,
};

export const headerProps = {
  title: <>Equipment</>,
  titleBorderWidth: styles.borderWidth,
  classNames: {
    header: styles.header,
    border: styles.headerBorder,
    titleAndBorderContainer: styles.headerTitleAndBorderContainer,
  },
};

export const sectionUnderHeaderProps = {
  contactButtonLabel: 'Empower your hardware',
  textPartOne: (
    <>
      Nowadays, we often focus on the visual and aesthetic aspects of the product and overlook its core. However,
      hardware, hidden under multiple abstraction layers and APIs, plays a vital role in the daily operations of every
      application and service.
      <br />
    </>
  ),
  textPartTwo: (
    <>
      Excellent products require reliable hardware. That’s why at CodiLime, we specialize in{' '}
      <b>unleashing your hardware’s full potential</b> by <b>accelerating and offloading</b> crucial parts of the
      system: virtual network functions, security features or application workload.
    </>
  ),
  classNames: {
    button: styles.sectionUnderHeaderButton,
    contentWrapper: styles.sectionUnderHeaderContentWrapper,
  },
};

export const blocksInRowsWithOneActiveGreyProps = {
  title: 'What can you expect from us?',
  subtitle: (
    <>
      Our mission is to provide you with the best solutions for your hardware. Whether it is the programming of your
      SmartNICs or the offloading of application workloads, we have years of experience and top talent on board to meet
      your needs.
      <br />
      This is how we can help you achieve your goal:
    </>
  ),
  blocks: [
    {
      title: 'General services',
      description: (
        <>
          End-to-end services which accelerate your system’s network datapath through hardware offloading and/or user
          space acceleration frameworks like DPDK.
        </>
      ),
    },
    {
      title: 'Hardware-related services',
      description: (
        <>
          Implementation of various network functions using FPGA (VHDL, P4) and embedded systems based on Arm and x86
          architectures.
        </>
      ),
    },
    {
      title: 'Driver-related services',
      description: (
        <>Driver implementation for custom network cards (including SmartNICs) within Linux kernel or DPDK.</>
      ),
    },
    {
      title: 'Application-related services',
      description: (
        <>
          Modification of system applications (like OVS, Tungsten Fabric vRouter) to offload particular network or
          application functions.
        </>
      ),
    },
    {
      title: 'Testing services',
      description: (
        <>
          Performance and functional verification of hardware and software solutions using a well-known testing toolset,
          for example, TRex, Ixia, Spirent. Whole process can be automated and connected with your CI/CD pipelines.
        </>
      ),
    },
  ],
  classNames: {
    section: styles.blocksInRowsWithOneActiveGrey,
    innerWrapper: styles.blocksInRowsWithOneActiveGreyInnerWrapper,
    subtitleStyles: styles.blocksInRowsWithOneActiveGreySubtitle,
    titlesContainer: styles.blocksInRowsWithOneActiveGreyTitlesContainer,
    blocksContainer: styles.blocksInRowsWithOneActiveGreyBlocksContainer,
  },
};

export const contactButtonImageSectionProps = {
  sectionProps: {
    classNames: {
      innerWrapper: styles.contactButtonImageSectionInnerWrapper,
      section: styles.contactButtonImageCustomSection,
      customContainer: styles.contactButtonImageCustomContainer,
    },
  },
  title: 'Unleash the full potential of your hardware with us',
  imageAlt: 'Unleash the full potential of your hardware',
  isH2: true,
  buttonTitle: 'Let’s talk',
  image: bannerImage,
  classNames: {
    customContent: styles.contactButtonImageSectionContent,
    imageContainer: styles.contactButtonImageSectionImageContainer,
    customContainer: styles.contactButtonImageSectionCustomContainer,
    customButton: styles.contactButtonImageSectionButton,
    customText: styles.contactButtonImageSectionText,
  },
};

export const blocksInRowsWithOneActiveProps = {
  id: 'expertise',
  title: 'Services that help develop your solution',
  subtitle: <>Explore our selected services that ensure the best care and growth opportunities for your product.</>,
  blocks: [
    {
      title: 'Low-level services',
      description: (
        <>
          With low-level programming services, we help you improve your overall system performance while keeping a small
          memory footprint at the same time. Our experts selected a bunch of technologies which can facilitate achieving
          these goals.
          <br />
          <br />
          We also build solutions that integrate programmable hardware with SDN/VNF/CNF software.
        </>
      ),
      buttonLabel: (
        <>
          <u>Deep dive into our low-level programming engineering services.</u>
        </>
      ),
      buttonHref: paths.SERVICES.LOW_LEVEL,
      colourfulButton: true,
    },
    {
      title: 'Embedded system services',
      description: (
        <>
          We know how to leverage embedded systems that operate on limited resources to provide you with the best
          results. Our specialists can help you port your application regardless of the system’s architecture. Moreover,
          we optimize the systems for embedded solutions and adjust Linux distribution to meet your and your hardware’s
          needs. You can also expect us to debug a particular embedded solution for you, and we are not strangers to
          black box testing of those.
        </>
      ),
    },
  ],
  classNames: {
    section: styles.blocksInRowsWithOneActive,
    innerWrapper: styles.blocksInRowsWithOneActiveInnerWrapper,
    subtitleStyles: styles.blocksInRowsWithOneActiveSubtitle,
    titlesContainer: styles.blocksInRowsWithOneActiveTitlesContainer,
    buttonWithArrows: styles.blocksInRowsWithOneActiveButtonWithArrows,
  },
};

export const opacityScrolledSliderProps = {
  title: 'Our work in practice',
  subtitle: (
    <>
      What are the benefits of implementing our equipment-related services into our clients’ products?
      <br />
      <br />
      Our client was looking for a solution that helps <b>improve the application performance</b> and take things out of
      the host machine. CodiLime’s developers and engineers suggested using a smartNIC card for offloading.
      <br />
      <br />
      The smartNIC implementation resulted in:
    </>
  ),
  textBlocks: [
    <>
      Increased the speed of application by <span>70%</span>
    </>,
    <>
      Increased the packet processing volume <span>5 times</span>
    </>,
    <>
      Decreased workload related to packet handling on the host machine by <span>85%</span>
    </>,
  ],
  blocksGap: 65,
  showOneBlock: true,
  topOpacity: 0.44,
  shiftDown: 0.3,
  classNames: {
    innerWrapper: styles.opacitySliderInnerWrapper,
    titlesContainer: styles.opacitySliderTitlesContainer,
  },
};

export const techStackProps = {
  sectionProps: {
    title: 'Technologies we use',
    subtitle: (
      <>
        No matter if the project will be closely related to low-level programming or embedded services. In every case,
        we use only <b>reliable, market-standardized technologies</b> to ensure a product’s best performance in the
        future.
      </>
    ),
    classNames: {
      titlesContainer: styles.techStackTitlesContainer,
      innerWrapper: styles.techStackInnerWrapper,
    },
  },
  classNames: {
    desktopContainer: styles.desktopContainer,
    techStackContainer: styles.techStackContainer,
    cardContainer: styles.techStackCardContainer,
    viewDetails: styles.techStackViewDetails,
  },
  tilesInLine: 5,
  data: [python, rust, go, cPlusPlus, dpdk, tRex, openVSwitch, sonic, tungstenFabric],
};
