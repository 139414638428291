import React from 'react';
import Layout from '@src/layouts';
import SEO from '@commons/SEO';

import {
  MobileHeaderLayoutB,
  DesktopHeaderLayoutB,
  SectionUnderHeaderWithUnderline,
  BlocksInRowsWithOneActive,
  ContactButtonImageSection,
  OpacityScrolledSlider,
  TechStack,
} from '@commons/index';

import {
  SEOProps,
  headerProps,
  sectionUnderHeaderProps,
  blocksInRowsWithOneActiveGreyProps,
  contactButtonImageSectionProps,
  blocksInRowsWithOneActiveProps,
  opacityScrolledSliderProps,
  techStackProps,
} from '@pages-impl/equipment/payload';

export default function EquipmentServices(props) {
  return (
    <Layout
      displayTopBanner={false}
      contactFormType="services"
      contactFormTitle="Build custom software for your hardware with us"
      {...props}
    >
      <SEO {...SEOProps} />
      <DesktopHeaderLayoutB {...headerProps} />
      <MobileHeaderLayoutB {...headerProps} />
      <SectionUnderHeaderWithUnderline {...sectionUnderHeaderProps} />
      <BlocksInRowsWithOneActive {...blocksInRowsWithOneActiveGreyProps} />
      <ContactButtonImageSection {...contactButtonImageSectionProps} />
      <BlocksInRowsWithOneActive {...blocksInRowsWithOneActiveProps} />
      <OpacityScrolledSlider {...opacityScrolledSliderProps} />
      <TechStack {...techStackProps} />
    </Layout>
  );
}
